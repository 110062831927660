import Vuex from 'vuex'
import Vue from 'vue'
import authenticate from './modules/Authenticate'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        authenticate
    }
});

export default store;