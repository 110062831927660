import Vue from 'vue'
import Router from 'vue-router'
import App from '../App';

const DeviceManagementAdmin = () => import('../pages/DeviceManagementAdmin')
const Dashboard = () => import('../pages/Dashboard/Dashboard')
const Camera = () => import('../pages/Camera/Camera');
const Equipment = () => import('../pages/Equipment/index');
const BillVideo = () => import('../pages/BillVideo/BillVideo');

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: DeviceManagementAdmin,
                    children: [
                        {
                            path: '',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: '/equipment/camera',
                            name: 'Camera',
                            component: Camera,
                        },
                        {
                            path: '/bills/video',
                            name: 'BillVideo',
                            component: BillVideo,
                        },
                        {
                            path:'danh-muc-thiet-bi',
                            name :'Danh mục thiết  bị',
                            component:Equipment
                        },
                        {
                            path:'chi-tiet-danh-muc-thiet-bi',
                            name :'Danh mục thiết  bị',
                            component:Equipment
                        }
                    ]
                }
            ]
        }
    ],
    mode: 'history',
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "exact-active" // active class for *exact* links.
})