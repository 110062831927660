import {AUTHENTICATE_SET_USER, AUTHENTICATE_SET_CREDENTIALS} from '../../mutation-types';
import axios from "axios";
import axiosRepository from '@/repository/Repository';
import config from '../../../configs/ApplicationConfig';

export default {
    async setUser({ commit }) {
        const { data: user } = await axios.get(config.timelineEndpoint + "/api/timeline/user");
        commit(AUTHENTICATE_SET_USER, { user });
    },
    async setCredentials({ commit }, credentials) {
        commit(AUTHENTICATE_SET_CREDENTIALS, { credentials })
    },

    async refreshToken({commit, state}) {
        const credentials = state.credentials;
        const { data } = await axios.put(`${config.API_APP_STAFF}/api/login/refresh-token`, null, {
            headers: {
                accessToken: credentials.AccessToken,
                idToken: credentials.IdToken,
                refreshToken: credentials.RefreshToken
            }
        });

        axiosRepository.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
        credentials.AccessToken = data.accessToken;
        commit(AUTHENTICATE_SET_CREDENTIALS, { credentials });
    }
}